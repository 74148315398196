import { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import Modal from './Modal';
import Button from '../../UIs/Button';

import cls from './linking.module.scss';

const Linking = () => {
  const { i18n, t: translate } = useTranslation('common');

  const [showModal, setShowModal] = useState(false);
  const googleBTN = useRef(null);
  const facebookBTN = useRef(null);

  const linkWithGoogle = () => {
    googleBTN.showLoading();

    setTimeout(() => {
      googleBTN.endLoading();
    }, 2500);
  }

  const linkWithFacebook = () => {
    facebookBTN.showLoading();

    setTimeout(() => {
      facebookBTN.endLoading();
    }, 2500);
  }

  return (
    <div className={cls.linking}>
      <div className={`${cls.form} ${cls[i18n.language]}`}>
        <button onClick={() => setShowModal(true)}><i className="fa-solid fa-at"></i> { translate('settings.linking.email') }</button>
        <Button ref={googleBTN} onClick={linkWithGoogle}><i className="fa-brands fa-google"></i> { translate('settings.linking.google') }</Button>
        <Button ref={facebookBTN} onClick={linkWithFacebook}><i className="fa-brands fa-facebook"></i> { translate('settings.linking.facebook') }</Button>
      </div>
      <div className={cls.image}>
        <i className="fi fi-rr-share"></i>
      </div>
      {
        showModal && (<Modal close={() => setShowModal(false)} />)
      }
    </div>
  )
}

export default Linking