import { useState, forwardRef } from 'react';

const Input = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.defaultValue || '');

  const handleChange = (e) => {
    setValue(e.target.value);
  }

  return (
    <div className={props.className ? props.className : undefined}>
      <label htmlFor={props.id}>{ props.placeholder }</label>
      <input placeholder={props.placeholder} type={props.type} value={value} onChange={handleChange} ref={ref} />
    </div>
  )
});

export default Input;