import { useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Button from "../../components/UIs/Button";
import Input from '../../components/UIs/Input';

import { success, error } from '../../utils/notify';

import cls from './reset.module.scss';

const Reset = () => {
  const { i18n, t: translate } = useTranslation('auth');
  const navigate = useNavigate();

  const resetBtn = useRef(null);

  const current_password = useRef(null);
  const new_password = useRef(null);
  const confirm_password = useRef(null);

  const handleReset = async () => {
    let credentials = {
      currentPassword: current_password.current.value,
      newPassword: new_password.current.value,
      confirmPassword: confirm_password.current.value
    };

    //= Validation
    if (!credentials.currentPassword) return error(translate('auth.error.requiredCurrentPassword'));
    if (!credentials.newPassword) return error(translate('auth.error.requiredNewPassword'));
    if (!credentials.confirmPassword) return error(translate('auth.error.requiredConfirmPassword'));
    if (credentials.confirmPassword !== credentials.newPassword) return error(translate('auth.error.passwordNotMatch'));

    resetBtn.showLoading();
    
    setTimeout(() => {
      success(translate('reset.reset_password_success'));
      navigate('/login');
      resetBtn.endLoading();
    }, 2000);
  }

  return (
    <div className={cls.reset}>
      <div className={cls.reset__container}>
        <div className={cls.reset__container__logo}>
          <img src="/assets/imgs/logo.png" alt="logo" />
          <h4>{ translate('title') }</h4>
        </div>

        <div className={cls.reset__container__title}>
          <h3>{ translate('reset.resetPassword') }</h3>
        </div>

        <div className={cls.reset__container__form}>
          <Input id="current_password" type="password" placeholder={ translate('auth.current_password') } className={`${cls.reset__container__form__input} ${cls[i18n.language]}`} ref={current_password} />
          <Input id="new_password" type="password" placeholder={ translate('auth.new_password') } className={`${cls.reset__container__form__input} ${cls[i18n.language]}`} ref={new_password} />
          <Input id="confirm_password" type="password" placeholder={ translate('auth.confirm_password') } className={`${cls.reset__container__form__input} ${cls[i18n.language]}`} ref={confirm_password} />
          <Button className={cls.reset__container__form__button} onClick={handleReset} ref={resetBtn}>{ translate('reset.resetBtn') }</Button>
        </div>
      </div>

      <div className={cls.reset__container__footer}>
        <p>
          { translate('auth.footer') }
        </p>
      </div>
    </div>
  )
}

export default Reset;