import { useState } from "react";
import Chart from "react-apexcharts";

const App = ({ data }) => {
  const [chartData] = useState({
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      zoom: {
        enabled: false
      },
      stroke: {
        curve: "smooth",
        width: 2
      },
      grid: {
        // show: false
      },
      labels: data.labels,
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        opposite: true
      }
    },
    series: data.series
  });

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type="area"
      width="100%"
      height={350}
    />
  );
}

export default App;