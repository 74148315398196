import { useRef } from 'react'

import { useTranslation } from 'react-i18next';

import Input from '../../UIs/Input';
import Button from '../../UIs/Button';

import cls from './account.module.scss';

const Account = () => {
  const { i18n, t: translate } = useTranslation('common');
  const username = useRef(null);
  const emailAddress = useRef(null);
  const pictureBtn = useRef(null);
  const infoBtn = useRef(null);

  const savePicture = () => {
    pictureBtn.showLoading();

    setTimeout(() => {
      pictureBtn.endLoading();
    }, 2500);
  }

  const saveInfo = () => {
    infoBtn.showLoading();

    setTimeout(() => {
      infoBtn.endLoading();
    }, 2500);
  }

  return (
    <div className={cls.account}>
      <div className={cls.account__picture}>
        <div className={cls['account__picture-container']}>
          <div className={cls['account__picture-overlay']}>
            <i className="fa-duotone fa-pen-to-square"></i>
          </div>
          <img src="https://cdn-icons-png.flaticon.com/512/149/149452.png" alt=""/>
        </div>
        <Button ref={pictureBtn} onClick={savePicture}>{ translate('save') }</Button>
      </div>
      <div className={`${cls.account__info} ${cls[i18n.language]}`}>
        <Input type="text" placeholder={translate('settings.account.username')} defaultValue="" className={cls['account__info-input']} ref={username} />
        <Input type="email" placeholder={translate('settings.account.email')} defaultValue="" className={cls['account__info-input']} ref={emailAddress} />
        <Button ref={infoBtn} onClick={saveInfo}>{ translate('save') }</Button>
      </div>
    </div>
  )
}

export default Account