import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import Breadcrumb from '../../components/Breadcrumb';
import Log from '../../components/Logs/Log';

import { DateRange } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'

import cls from './logs.module.scss';

const Logs = () => {
  const { i18n, t: translate } = useTranslation('common');
  const [date, setDate] = useState([{
    startDate: dayjs().startOf('day').toDate(),
    endDate: new Date(),
    key: 'selection'
  }]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  
  const handleDateChange = (item) => {
    setDate([item.selection]);
  };

  const handleDateFocusChange = (focused) => {
    if (!focused[0] && !focused[1]) setShowDatePicker(false);
  };

  const fetchDataBasedOnDateChange = () => {
    console.log(date);
  }

  useEffect(() => {
    fetchDataBasedOnDateChange();
  }, [date]);

  return (
    <div className={cls.logs}>
      <Breadcrumb page="logs" />
      <div className={`${cls.logs_datepicker} ${cls[i18n.language]}`}>
        <input
          type="text"
          readOnly
          value={dayjs(date[0].startDate).format('YYYY-MM-DD') + `  ${translate('orders.topnav.to')}  ` + dayjs(date[0].endDate || new Date()).format('YYYY-MM-DD')} 
          onClick={() => setShowDatePicker(prev => !prev)}
        />
        <i onClick={() => setShowDatePicker(prev => !prev)} className="fa-light fa-calendar-day"></i>
        {
          showDatePicker &&
            <DateRange
              editableDateInputs={false}
              onChange={handleDateChange}
              onRangeFocusChange={handleDateFocusChange}
              ranges={date}
              retainEndDateOnFirstSelection={false}
              showDateDisplay={false}
              className={i18n.language}
            />
          }
      </div>
      <div className={cls.content}>
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
        <Log />
      </div>
    </div>
  )
}

export default Logs