import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Map from './Map';
import Accordion from './Accordion';

import dayjs from 'dayjs';

import Formatter from 'react-currency-format';
import Tooltip from '@mui/material/Tooltip';

import cls from './order-details.module.scss';

const OrderDetails = ({ close, openStatusModal }) => {
  const { i18n, t: translate } = useTranslation('common');
  const [itemsOpen, setIsItemsOpen] = useState(false);
  const [timelineOpen, setIsTimelineOpen] = useState(true);

  const toggleItems = (e) => {
    setIsItemsOpen(p => !p);
  }

  const toggleTimline = (e) => {
    setIsTimelineOpen(p => !p);
  }

  return (
    <div className={cls.order_details}>
      <div className={cls.order_details_header}>
        <h4>{ translate('orders.orderS') } (829769964858)</h4>
        <Tooltip title="Close" arrow>
          <i className="fa-solid fa-xmark" onClick={close}></i>
        </Tooltip>
      </div>
      <div className={`${cls.order_details_meta} ${cls[i18n.language]}`}>
        <div className={`${cls.order_details_status}`}>
          <span><i className="fa-light fa-circle-exclamation"></i> { translate('orders.onDelivery') }</span>
        </div>
        <div className={cls.order_details_expected}>
          <span><i className="fa-duotone fa-calendar-clock"></i> { dayjs(new Date()).format("YYYY-MM-DD") }</span>
        </div>
      </div>
      <div className={cls.order_details_address}>
        <div className={`${cls.order_details_address_details} ${cls[i18n.language]}`}>
          <span><i className="fa-duotone fa-map-location-dot"></i> 23street, Sohag, Egypt</span>
        </div>
        <div className={cls.order_details_address_map}>
          <Map location={{ "lat": 26.547748, "lng": 31.699264 }} />
        </div>
      </div>
      <div className={`${cls.order_details_user} ${cls[i18n.language]}`}>
        <p><img src="https://cdn-icons-png.flaticon.com/512/149/149452.png" alt="user pic" /> Hassan Ali</p>
        <p><i className="fa-light fa-mobile"></i> +201146321817</p>
      </div>
      <div className={cls.order_details_items}>
        <h4 onClick={toggleItems}>{ translate('orders.items') } (3) - <Formatter value={227500} thousandSeparator={true} displayType="text" prefix='$' /> <i className={`fa-light fa-angle-up ${itemsOpen ? '' : cls.closed} ${cls[i18n.language]}`}></i></h4>
        <Accordion className={cls.order_details_items_list} open={itemsOpen}>
          <div className={cls.order_details_item}>
            <img src="https://storage.googleapis.com/e-commerce-344518.appspot.com/images/product-Lenovo%20IdeaPad%20Gaming%203%20Laptop%20-%20Ryzen%205%205600H%206-Core%2C%2016GB%20RAM%2C%20512GB%20SSD%2C%20NVIDIA%20RTX%203060%206GB%20GDDR6%20Graphics%2C%2015.6%22%20FHD%201920x1080%20IPS%20165Hz%2C%204Zone%20RGB%20Backlit%20Keyboard%2C%20Dos%2C%20black%20%2B%20Gaming%20RGB%20Mouse/image_1654741636293?GoogleAccessId=firebase-adminsdk-eqi98%40e-commerce-344518.iam.gserviceaccount.com&Expires=4102437600&Signature=eHJbESR2IFG5Ul3UfUaki1sF8wr1ppkLALWfSbNUR8IcTMPnMlgXpLaRCgn84ktU6iBSk24KhPNnoCtGR2IEALPsauuwwuKOfuN6spf%2B7MO6KAVPjwFYg0qOFsTVuuQiVzUXTOioO7jZxjhy96u%2BP47GzaTKWiciz7cMLEtu8J%2BgQVp5ctreETggux5AnYlQagGZVGMc1%2B1momua%2FtJz1n%2BWhbZRvZP9v%2F%2B7py7pvuiEoU3J53UW%2BeUwOXdidA58LerHvcxF3puTKtE1PywruT3lMxr17fmfah2DlUNhJk7VH3f7WX3evjewNGARAn3rCwG8rlXz5oJFLaeFkrwRaw%3D%3D" alt="" />
            <div>
              <p>Lenovo IdeaPad Gaming 3 Laptop - Ryzen 5 5600H 6-Core...</p>
              <p>{ translate('orders.qty') }: (<b>5</b>) &nbsp;-&nbsp; { translate('orders.total') } (<b><Formatter value={42500} thousandSeparator={true} displayType="text" prefix='$' /></b>)</p>
              <p>{ translate('orders.color') }: <span className={cls.color} /> &nbsp;-&nbsp; { translate('orders.variant') } (<b>32GB RAM</b>)</p>
            </div>
          </div>
          <div className={cls.order_details_item}>
            <img src="https://storage.googleapis.com/e-commerce-344518.appspot.com/images/product-Lenovo%20IdeaPad%20Gaming%203%20Laptop%20-%20Ryzen%205%205600H%206-Core%2C%2016GB%20RAM%2C%20512GB%20SSD%2C%20NVIDIA%20RTX%203060%206GB%20GDDR6%20Graphics%2C%2015.6%22%20FHD%201920x1080%20IPS%20165Hz%2C%204Zone%20RGB%20Backlit%20Keyboard%2C%20Dos%2C%20black%20%2B%20Gaming%20RGB%20Mouse/image_1654741636293?GoogleAccessId=firebase-adminsdk-eqi98%40e-commerce-344518.iam.gserviceaccount.com&Expires=4102437600&Signature=eHJbESR2IFG5Ul3UfUaki1sF8wr1ppkLALWfSbNUR8IcTMPnMlgXpLaRCgn84ktU6iBSk24KhPNnoCtGR2IEALPsauuwwuKOfuN6spf%2B7MO6KAVPjwFYg0qOFsTVuuQiVzUXTOioO7jZxjhy96u%2BP47GzaTKWiciz7cMLEtu8J%2BgQVp5ctreETggux5AnYlQagGZVGMc1%2B1momua%2FtJz1n%2BWhbZRvZP9v%2F%2B7py7pvuiEoU3J53UW%2BeUwOXdidA58LerHvcxF3puTKtE1PywruT3lMxr17fmfah2DlUNhJk7VH3f7WX3evjewNGARAn3rCwG8rlXz5oJFLaeFkrwRaw%3D%3D" alt="" />
            <div>
              <p>Lenovo IdeaPad Gaming 3 Laptop - Ryzen 5 5600H 6-Core...</p>
              <p>Qty: (<b>5</b>) &nbsp;-&nbsp; Total (<b><Formatter value={42500} thousandSeparator={true} displayType="text" prefix='$' /></b>)</p>
              <p>Color: <span className={cls.color} /> &nbsp; - &nbsp; Variant (<b>32GB RAM</b>)</p>
            </div>
          </div>
          <div className={cls.order_details_item}>
            <img src="https://storage.googleapis.com/e-commerce-344518.appspot.com/images/product-Lenovo%20IdeaPad%20Gaming%203%20Laptop%20-%20Ryzen%205%205600H%206-Core%2C%2016GB%20RAM%2C%20512GB%20SSD%2C%20NVIDIA%20RTX%203060%206GB%20GDDR6%20Graphics%2C%2015.6%22%20FHD%201920x1080%20IPS%20165Hz%2C%204Zone%20RGB%20Backlit%20Keyboard%2C%20Dos%2C%20black%20%2B%20Gaming%20RGB%20Mouse/image_1654741636293?GoogleAccessId=firebase-adminsdk-eqi98%40e-commerce-344518.iam.gserviceaccount.com&Expires=4102437600&Signature=eHJbESR2IFG5Ul3UfUaki1sF8wr1ppkLALWfSbNUR8IcTMPnMlgXpLaRCgn84ktU6iBSk24KhPNnoCtGR2IEALPsauuwwuKOfuN6spf%2B7MO6KAVPjwFYg0qOFsTVuuQiVzUXTOioO7jZxjhy96u%2BP47GzaTKWiciz7cMLEtu8J%2BgQVp5ctreETggux5AnYlQagGZVGMc1%2B1momua%2FtJz1n%2BWhbZRvZP9v%2F%2B7py7pvuiEoU3J53UW%2BeUwOXdidA58LerHvcxF3puTKtE1PywruT3lMxr17fmfah2DlUNhJk7VH3f7WX3evjewNGARAn3rCwG8rlXz5oJFLaeFkrwRaw%3D%3D" alt="" />
            <div>
              <p>Lenovo IdeaPad Gaming 3 Laptop - Ryzen 5 5600H 6-Core...</p>
              <p>Qty: (<b>5</b>) &nbsp;-&nbsp; Total (<b><Formatter value={42500} thousandSeparator={true} displayType="text" prefix='$' /></b>)</p>
              <p>Color: <span className={cls.color} /> &nbsp; - &nbsp; Variant (<b>32GB RAM</b>)</p>
            </div>
          </div>
        </Accordion>
      </div>
      <div className={cls.order_details_track_timing}>
        <h4 onClick={toggleTimline}>{ translate('orders.timeline') } <i className={`fa-light fa-angle-up ${timelineOpen ? '' : cls.closed} ${cls[i18n.language]}`}></i></h4>
        <Accordion className={cls.order_details_items_list} open={timelineOpen}>
          <div className={`${cls.order_details_track_timing_state} ${cls[i18n.language]}`}>
            <p><i className={`fa-duotone fa-hand-holding-box ${cls[i18n.language]}`}></i> { translate('orders.received') }</p>
            <p><i className="fa-duotone fa-clock"></i> { dayjs(new Date(new Date().getTime() - 24*62*60*1000)).format("YYYY-MM-DD hh:mm A") }</p>
          </div>
          <div className={`${cls.order_details_track_timing_state} ${cls[i18n.language]}`}>
            <p><i className={`fa-duotone fa-truck-fast ${cls[i18n.language]}`}></i> { translate('orders.onDelivery') }</p>
            <p><i className="fa-duotone fa-clock"></i> { dayjs(new Date()).format("YYYY-MM-DD hh:mm A") }</p>
          </div>
          <div className={`${cls.order_details_track_timing_state} ${cls[i18n.language]}`}>
            <p><i className={`fa-duotone fa-box-circle-check ${cls[i18n.language]}`}></i> { translate('orders.delivered') }</p>
            <p><span>{ translate('orders.notChecked') }</span></p>
          </div>
        </Accordion>
      </div>
      <div className={cls.order_details_action}>
        <button onClick={openStatusModal}>{ translate('orders.changeBtn') }</button>
      </div>
    </div>
  )
}

export default OrderDetails