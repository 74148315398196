import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Formatter from 'react-currency-format';
import Tooltip from '@mui/material/Tooltip';

import dayjs from 'dayjs';

import cls from './order-box.module.scss';

const OrderBox = ({ status, showDetails }) => {
  const { i18n, t: translate } = useTranslation('common');
  const navigate = useNavigate();

  const generateReport = () => {
    navigate(`/reports/order-details?order=${829769964858}`);
  }

  return (
    <div className={`${cls.orderbox} ${cls[i18n.language]}`}>
      <div className={cls.orderbox_section}>
        <div className={cls.orderbox_section_number}>
          <span><i className="fa-duotone fa-box-taped"></i>{ translate('orders.order') }</span>
          <p>829769964858</p>
        </div>
        <div className={cls.orderbox_section_status}>
          <span><i className="fa-duotone fa-circle-exclamation-check"></i>{ translate('orders.status') }</span>
          <p className={cls[status]}>{ translate(`orders.${status}`) }</p>
        </div>
      </div>
      <div className={cls.orderbox_section}>
        <div className={cls.orderbox_section_items}>
          <span><i className="fa-duotone fa-boxes-stacked"></i>{ translate('orders.items') }</span>
          <p>7 { translate('orders.itemsS') } - <Formatter value={27500} thousandSeparator={true} displayType="text" prefix='$' /></p>
        </div>
        <div className={cls.orderbox_section_delivery}>
          <span><i className="fa-duotone fa-calendar-clock"></i>{ translate('orders.expected') }</span>
          <p>{ dayjs(new Date()).format("YYYY-MM-DD") }</p>
        </div>
      </div>
      <div className={cls.orderbox_section}>
        <div className={cls.orderbox_section_address}>
          <p><span><i className="fa-duotone fa-map-location-dot"></i></span> 23street, Sohag, Egypt</p>
        </div>
      </div>
      <div className={cls.orderbox_section}>
        <div className={cls.orderbox_section_user}>
          <img src="https://cdn-icons-png.flaticon.com/512/149/149452.png" alt="user pic" />
          <p>Hassan Ali</p>
        </div>
        <div className={cls.orderbox_section_actions}>
          <Tooltip title={ translate('orders.print') } arrow>
            <i className={`fa-duotone fa-print ${cls.print}`} onClick={generateReport}></i>
          </Tooltip>
          <Tooltip title={ translate('orders.details') } arrow>
            <i className="fa-duotone fa-circle-info" onClick={showDetails}></i>
          </Tooltip>
          <Tooltip title={ translate('orders.call') } arrow>
            <a href="tel:+201146321817"><i className={`fa-duotone fa-circle-phone-flip ${cls.call}`}></i></a>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default OrderBox