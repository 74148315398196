import { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import Button from "../../components/UIs/Button";
import Input from '../../components/UIs/Input';

import { success, error } from '../../utils/notify';

import cls from './forget.module.scss';

const Forget = () => {
  const { i18n, t: translate } = useTranslation('auth');

  const sendBtn = useRef(null);

  const email = useRef(null);

  const handleReset = async () => {
    let credentials = {
      email: email.current.value,
      client: 'delivery'
    };

    //= Validation
    if (!credentials.email) return error(translate('auth.error.requiredEmail'));
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(credentials.email)) return error(translate('auth.error.invalidEmail'));

    sendBtn.showLoading();
    
    setTimeout(() => {
      success(translate('reset_password_success'));
      sendBtn.endLoading();
    }, 2000);
  }

  return (
    <div className={cls.forget}>
      <div className={cls.forget__container}>
        <div className={cls.forget__container__logo}>
          <img src="/assets/imgs/logo.png" alt="logo" />
          <h4>{ translate('title') }</h4>
        </div>

        <div className={cls.forget__container__title}>
          <h3>{ translate('forget.forgetPassword') }</h3>
        </div>

        <div className={cls.forget__container__form}>
          <Input id="email" type="email" placeholder={ translate('auth.email') } className={`${cls.forget__container__form__input} ${cls[i18n.language]}`} ref={email} />
          <Button className={cls.forget__container__form__button} onClick={handleReset} ref={sendBtn}>{ translate('forget.sendEmail') }</Button>
        </div>
      </div>

      <div className={cls.forget__container__footer}>
        <p>
          { translate('auth.footer') }
        </p>
      </div>
    </div>
  )
}

export default Forget;