import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';

import { error } from '../../../utils/notify';

import cls from './report.module.scss';

const Report = ({ type, title, details }) => {
  const [openModal, setOpenModal] = useState(false);
  const { i18n, t: translate } = useTranslation('common');
  const navigate = useNavigate();

  const generateReport = () => {
    const from = document.getElementById('from');
    const to = document.getElementById('to');
    const orderId = document.getElementById('order');

    var q = '';

    if (from && from.value) q += `&from=${from.value}`;
    if (to && to.value) q += `&to=${to.value}`;
    if (orderId && orderId.value) q += `&order=${orderId.value}`;

    if (type === 'order-details' && !orderId?.value) {
      setOpenModal(false);
      return error(translate('reports.err.enterOrderId'));
    }

    navigate(`/reports/${type}?${q.slice(1)}`);
  }

  return (
    <div className={`${cls.report} ${cls[i18n.language]}`}>
      <i className="fi fi-rr-document-signed"></i>
      <div className={cls.report_title}>
        <h3>{ title }</h3>
      </div>
      <div className={cls.report_body}>
        <p>{ details }</p>
      </div>
      <div className={cls.report_action}>
        <button className={cls.report_action_button} onClick={() => setOpenModal(true)}>
          <i className="fi fi-rr-document"></i>
          <span>{ translate('reports.generate') }</span>
        </button>
      </div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div className={`${cls.modal} ${cls[i18n.language]}`}>
          <Tooltip title="Close" arrow>
            <i className="fa-solid fa-xmark" onClick={() => setOpenModal(false)}></i>
          </Tooltip>
          <h3>{ translate('reports.generateReport') } { title }</h3>
          <div className={cls.modal_body}>
            {
              type === 'order-details' ? (
                <>
                  <label htmlFor="order">{ translate('reports.typeOrderId') }</label>
                  <input type="text" id="order" placeholder={ translate('reports.typeOrderId') } />
                </>
              )
              :
              (
                <>
                  <label htmlFor="from">{ translate('reports.from') }: </label>
                  <input type="date" id="from" defaultValue={dayjs(new Date()).format('YYYY-MM-DD')} />
                  <label htmlFor="to">{ translate('reports.to') }: </label>
                  <input type="date" id="to" />
                </>
              )
            }
          </div>
          <button onClick={generateReport}>{ translate('reports.generateReport') }</button>
        </div>
      </Modal>
    </div>
  )
}

export default Report