import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { DateRange } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import cls from './tab-nav.module.scss';

const TabNav = () => {
  const { i18n, t: translate } = useTranslation('common');

  const [date, setDate] = useState([{
    startDate: dayjs().startOf('day').toDate(),
    endDate: new Date(),
    key: 'selection'
  }]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  
  const handleDateChange = (item) => {
    setDate([item.selection]);
  };

  const handleDateFocusChange = (focused) => {
    if (!focused[0] && !focused[1]) setShowDatePicker(false);
  };

  const fetchDataBasedOnDateChange = () => {
    console.log(date);
  }

  useEffect(() => {
    fetchDataBasedOnDateChange();
  }, [date]);

  const openTab = (tab) => {
    let btns = document.querySelectorAll(`.${cls.tabnav_tablinks_item}`);
    let currentBtn = document.querySelector(`#${tab}`);

    btns.forEach(btn => btn.classList.remove(cls.active));
    currentBtn.classList.add(cls.active)
  }

  return (
    <div className={cls.tabnav}>
      <div className={cls.tabnav_tablinks}>
        <div className={`${cls.tabnav_tablinks_item} ${cls.active}`} id="all" onClick={() => openTab('all')}>
          <span className={cls[i18n.language]}><i className="fa-duotone fa-circle-star"></i> { translate('orders.all') } (127)</span>
        </div>
        <div className={cls.tabnav_tablinks_item} id="received" onClick={() => openTab('received')}>
          <span className={cls[i18n.language]}><i className="fa-duotone fa-hand-holding-box"></i> { translate('orders.received') } (7)</span>
        </div>
        <div className={cls.tabnav_tablinks_item} id="onDelivery" onClick={() => openTab('onDelivery')}>
          <span className={cls[i18n.language]}><i className="fa-duotone fa-truck-fast"></i> { translate('orders.onDelivery') } (15)</span>
        </div>
        <div className={cls.tabnav_tablinks_item} id="delivered" onClick={() => openTab('delivered')}>
          <span className={cls[i18n.language]}><i className="fa-duotone fa-box-circle-check"></i> { translate('orders.delivered') } (87)</span>
        </div>
        <div className={cls.tabnav_tablinks_item} id="cancelled" onClick={() => openTab('cancelled')}>
          <span className={cls[i18n.language]}><i className="fa-duotone fa-calendar-xmark"></i> { translate('orders.cancelled') } (12)</span>
        </div>
      </div>
      <div className={`${cls.tabnav_datepicker} ${cls[i18n.language]}`}>
        <input
          type="text"
          readOnly
          value={dayjs(date[0].startDate).format('YYYY-MM-DD') + `  ${translate('orders.topnav.to')}  ` + dayjs(date[0].endDate || new Date()).format('YYYY-MM-DD')} 
          onClick={() => setShowDatePicker(prev => !prev)}
        />
        <i onClick={() => setShowDatePicker(prev => !prev)} className="fa-light fa-calendar-day"></i>
        {
          showDatePicker &&
            <DateRange
              editableDateInputs={false}
              onChange={handleDateChange}
              onRangeFocusChange={handleDateFocusChange}
              ranges={date}
              retainEndDateOnFirstSelection={false}
              showDateDisplay={false}
              className={i18n.language}
            />
          }
      </div>
    </div>
  )
}

export default TabNav