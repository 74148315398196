import { useState, useMemo, createContext } from "react";

import Cookies from "universal-cookie";

import { createTheme, ThemeProvider } from "@mui/material/styles";

export const ThemeContext = createContext(null);

const ThemeContextProvide = ({ children }) => {
  const cookie = new Cookies();
  const [lang] = useState(cookie.get('markety-delivery-language') || 'en');

  let theme = useMemo(() => createTheme({
    palette: {
      currentMode: 'light',
      primary: {
        main: '#40739e'
      },
      divider: '#40739e',
      background: {
        default: '#F8F9FA',
        secondary: "#fff"
      },
      text: {
        primary: '#40739e',
        secondary: '#40739e',
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1480
      }
    },
    typography: {
      fontFamily: lang === 'ar' ? 'Baloo Bhaijaan 2' : 'Rubik',
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      h1: {
        fontWeight: 500
      },
      h2: {
        fontWeight: 500
      },
      h3: {
        fontWeight: 500
      },
      h4: {
        fontWeight: 500
      },
      h5: {
        fontWeight: 500
      },
      h6: {
        fontWeight: 500
      },
      subtitle1: {
        fontWeight: 400
      },
      subtitle2: {
        fontWeight: 400
      },
      body1: {
        fontWeight: 400
      },
      body2: {
        fontWeight: 400
      },
      caption: {
        fontWeight: 400
      },
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '0.75rem',
            padding: '7px 15px',
            backgroundColor: "rgba(0, 0, 0, 0.9)"
          }
        }
      },
      MuiSkeleton: {
        defaultProps: {
          animation: 'wave'
        }
      }
    },
    direction: `${lang === 'en' ? 'ltr' : 'rtl'}`,
  }), [lang]);

  return (
    <ThemeContext.Provider value={{ theme }}>
      <ThemeProvider theme={theme}>
        { children }
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeContextProvide;