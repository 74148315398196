import MapPicker from 'react-google-map-picker';

const Map = ({ location }) => {
  return (
    <MapPicker
      defaultLocation={location}
      zoom={9}
      mapTypeId="roadmap"
      style={{height:'250px'}}
      apiKey='AIzaSyA5bpEs3xlB8vhxNFErwoo3MXR64uavf6Y'
    />
  )
}

export default Map;