import { useState } from 'react';

import Breadcrumb from '../../components/Breadcrumb';
import Nav from '../../components/Settings/Nav';
import Account from '../../components/Settings/Account';
import Security from '../../components/Settings/Security';
import Linking from '../../components/Settings/Linking';
import Notifications from '../../components/Settings/Notifications';

const Settings = () => {
 const [tab, setTab] = useState('account');

  return (
    <div>
      <Breadcrumb page="settings" />
      <Nav openTab={(t) => setTab(t)} activeTab={tab} />
      <div className="content">
        {tab === 'account' && <Account />}
        {tab === 'security' && <Security />}
        {tab === 'linking' && <Linking />}
        {tab === 'notifications' && <Notifications />}
      </div>
    </div>
  )
}

export default Settings;