import React from 'react';

import { useTranslation } from 'react-i18next';

import Formatter from 'react-currency-format';
import AreaChart from './AreaChart';

import cls from "./earnings.module.scss";

const Earnings = () => {
  const { i18n, t: translate } = useTranslation('common');

  const chartData = {
    labels: [translate('dashboard.summary.onDelivery'), translate('dashboard.summary.delivered'), translate('dashboard.summary.cancelled')],
    series: [
      {
        color: "#00AD1C",
        data: [
          [1327359600000, 2500],
          [1327446000000, 2700],
          [1327532400000, 1200],
          [1327618800000, 9620],
          [1327878000000, 4850],
          [1327964400000, 3600],
          [1328050800000, 3500],
          [1328137200000, 500],
          [1328223600000, 1580],
          [1328482800000, 3200],
          [1328569200000, 2500],
          [1328655600000, 6450],
          [1328742000000, 11500],
          [1328828400000, 4200],
          [1329087600000, 1500]
        ]
      }
    ]
  }
  
  return (
    <div className={cls.earnings}>
      <div className={cls.earnings__title}>
        <h4>{ translate('dashboard.earnings.title') }</h4>
      </div>
      <div className={cls.earnings__filter}>
        <select>
          <option>{ translate('dashboard.filter.lweek') }</option>
          <option>{ translate('dashboard.filter.l30days') }</option>
          <option>{ translate('dashboard.filter.lmonth') }</option>
          <option>{ translate('dashboard.filter.lyear') }</option>
        </select>
      </div>
      <div className={cls.earnings__charts}>
        <div className={cls.earnings__number}>
          <h3>{ translate('dashboard.earnings.title') }</h3>
          <h1><Formatter value={24560} thousandSeparator={true} displayType="text" prefix='$' /></h1>
          <h6 className={cls.inc}>(5.25 %) <i className="fa-solid fa-arrow-trend-up"></i></h6>
        </div>
        <AreaChart data={chartData} />
      </div>
    </div>
  )
}

export default Earnings;