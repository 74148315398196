import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import OrderBox from './Box';
import OrderDetails from './Details';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';

import cls from './order-boxes.module.scss';

const OrderBoxes = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { i18n, t: translate } = useTranslation('common');
  const [showOrderDetails, setShowDetails] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(+searchParams.get('p') || 1);
  const [totalPages, setTotalPages] = useState(5);

  const handleChangePage = (e, pge) => {
    setPage(pge);
    setSearchParams({ p: pge });
  }

  const showDetails = () => {
    setShowDetails(true);
  }

  return (
    <div className={cls.orderboxes}>
      {/* Orders */}
      <OrderBox status='received' showDetails={showDetails} />
      <OrderBox status='onDelivery' showDetails={showDetails} />
      <OrderBox status='onDelivery' showDetails={showDetails} />
      <OrderBox status='onDelivery' showDetails={showDetails} />
      <OrderBox status='onDelivery' showDetails={showDetails} />
      <OrderBox status='delivered' showDetails={showDetails} />
      <OrderBox status='delivered' showDetails={showDetails} />
      <OrderBox status='cancelled' showDetails={showDetails} />
      <OrderBox status='delivered' showDetails={showDetails} />
      <OrderBox status='delivered' showDetails={showDetails} />
      <OrderBox status='cancelled' showDetails={showDetails} />
      <OrderBox status='cancelled' showDetails={showDetails} />
      <OrderBox status='delivered' showDetails={showDetails} />
      {/* Pagination */}
      <Stack spacing={2} sx={{ mt: 5, ml: 'auto' }} className={`${cls.pagination} ${cls[i18n.language]}`}>
        <Pagination shape="rounded" count={totalPages} onChange={handleChangePage} page={page} />
      </Stack>
      {
        showOrderDetails && (<OrderDetails close={() => setShowDetails(false)} openStatusModal={() => setOpenModal(true)} />)
      }
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div className={`${cls.modal} ${cls[i18n.language]}`}>
          <Tooltip title="Close" arrow>
            <i className="fa-solid fa-xmark" onClick={() => setOpenModal(false)}></i>
          </Tooltip>
          <h3>{ translate('orders.orderS') } (829769964858)</h3>
          <p><i className="fa-duotone fa-truck-fast"></i>{ translate('orders.cStatus') } : <span>{ translate('orders.received') }</span></p>
          <select>
            <option disabled={true}>{ translate('orders.received') }</option>
            <option>{ translate('orders.onDelivery') }</option>
            <option>{ translate('orders.delivered') }</option>
            <option>{ translate('orders.cancelled') }</option>
          </select>
          <button>{ translate('save') }</button>
        </div>
      </Modal>
    </div>
  )
}

export default OrderBoxes