import React from 'react';

import { useTranslation } from 'react-i18next';

import { NavLink } from 'react-router-dom'

import cls from './sidebar.module.scss';

const Sidebar = () => {
  const { i18n, t: translate } = useTranslation('common');

  const navLinks = [
    {
      name: translate('nav.dashboard'),
      to: '/',
      icon: <i className="fa-thin fa-chart-pie"></i>
    },
    {
      name: translate('nav.orders'),
      to: '/orders',
      icon: <i className="fa-thin fa-box-check"></i>
    },
    {
      name: translate('nav.reports'),
      to: '/reports',
      icon: <i className="fa-thin fa-file-lines"></i>
    },
    {
      name: translate('nav.logs'),
      to: '/logs',
      icon: <i className="fa-thin fa-circle-exclamation"></i>
    },
    {
      name: translate('nav.settings'),
      to: '/settings',
      icon: <i className="fa-thin fa-gear"></i>
    }
  ]

  return (
    <div className={cls.sidebar}>
      <ul className={cls.sidebar__menu}>
        {
          navLinks.map((link, i) => (
            <li key={i} className={cls.sidebar__menu_item}>
              <NavLink to={link.to} className={({isActive}) => `${cls.sidebar__menu_link} ${cls[i18n.language]}` + (isActive ? ` ${cls.sidebar__menu_link_active}` : "") }>
                { link.icon } <span>{ link.name }</span>
              </NavLink>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default Sidebar