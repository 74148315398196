import { useState } from 'react';

import dayjs from 'dayjs';

import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import cls from './log.module.scss';

const Log = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className={cls.log} onClick={() => setOpen(p => !p)}>
      <div className={cls.log_details_header}>
        <h5><i className="fi fi-rr-record-vinyl"></i> Remove Multiple User Accounts</h5>
        <div className={cls.log_details_header_actions}>
          <span>{ dayjs(new Date()).format('DD-MM-YYYY hh:mm A') }</span>
          <i className={`fi fi-rr-angle-${open ? 'up':'down'}`}></i>
        </div>
      </div>
      <SlideDown className={cls.log_details}>
        {
          open ?
          (
            <div className={cls.log_details_content}>
              <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed corporis voluptatem, labore facilis explicabo soluta aliquid quod quis aut placeat quam perspiciatis nam omnis autem ipsam incidunt et. Quam, cupiditate.
              </span>
            </div>
          )
          : 
          null
        }
      </SlideDown>
    </div>
  )
}

export default Log