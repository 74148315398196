import React from 'react';

import { useTranslation } from 'react-i18next';

import Report from './Report';

import cls from './all-reports.module.scss';

const AllReports = () => {
  const { t: translate } = useTranslation('common');

  const reports = [
    {
      title: translate('reports.delivered'),
      details: translate('reports.delivered.details'),
      type: 'delivered-orders'
    },
    {
      title: translate('reports.cancelled'),
      details: translate('reports.cancelled.details'),
      type: 'cancelled-orders'
    },
    {
      title: translate('reports.details'),
      details: translate('reports.details.details'),
      type: 'order-details'
    },
    {
      title: translate('reports.customers'),
      details: translate('reports.customers.details'),
      type: 'customers'
    },
    {
      title: translate('reports.amounts'),
      details: translate('reports.amounts.details'),
      type: 'amounts'
    }
  ];

  return (
    <div className={cls.reports}>
      {
        reports.map((report, idx) => (
          <Report key={idx} title={report.title} details={report.details} type={report.type} />
        ))
      }
    </div>
  )
}

export default AllReports