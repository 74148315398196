import { useState, useEffect, useRef, useCallback, forwardRef } from 'react';

import cls from "./styles/icon-input.module.scss";

const IconInput = forwardRef((props, ref) => {
  const [value, setValue] = useState('');
  const [content, setContent] = useState('');
  const IconRef = useRef(null);

  const handleChange = (e) => {
    setValue(e.target.value);
  }

  const showLoading = useCallback(() => {
    setContent(IconRef.current.innerHTML);
    IconRef.current.innerHTML = `<i class="fas fa-spinner-third fa-spin ${cls.spin}"></i>`;
  }, [IconRef]);

  const endLoading = useCallback(() => {
    IconRef.current.innerHTML = content;
  }, [IconRef, content]);

  useEffect(() => {
    if (ref) {
      ref.showLoading = showLoading;
      ref.endLoading = endLoading;
    }
  }, [ref, endLoading, showLoading]);

  return (
    <div className={`${props.className} ${cls['icon-input']}`}>
      <span onClick={props.onAction} ref={IconRef}>
        { props.icon }
      </span>
      <input
        type={props.type}
        placeholder={props.placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={(e) => e.keyCode === 13 && props.onAction()}
        ref={ref}
      />
    </div>
  )
});

export default IconInput;