import { createSlice } from "@reduxjs/toolkit";

var initialState = { user: null };

const User = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    }
  }
})

export const UserActions = User.actions

export default User.reducer;