import React from 'react'

import { useTranslation } from 'react-i18next';

import cls from './nav.module.scss';

const Nav = ({ activeTab, openTab }) => {
  const { i18n, t: translate } = useTranslation('common');

  return (
    <nav className={`${cls.nav} ${cls[i18n.language]}`}>
      <ul>
        <li>
          <button onClick={() => openTab('account')} className={activeTab === 'account' ? cls.active : null}>
            <i className="fi fi-rr-user"></i> { translate('settings.account') }
          </button>
        </li>
        <li>
          <button onClick={() => openTab('security')} className={activeTab === 'security' ? cls.active : null}>
            <i className="fi fi-rr-unlock"></i> { translate('settings.security') }
          </button>
        </li>
        <li>
          <button onClick={() => openTab('linking')} className={activeTab === 'linking' ? cls.active : null}>
            <i className="fi fi-rr-share"></i> { translate('settings.linking') }
          </button>
        </li>
        <li>
          <button onClick={() => openTab('notifications')} className={activeTab === 'notifications' ? cls.active : null}>
            <i className="fi fi-rr-bell"></i> { translate('settings.notifications') }
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default Nav