import { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import Input from '../../../UIs/Input';
import Tooltip from '@mui/material/Tooltip';

import cls from './modal.module.scss';

const OrderDetails = ({ close }) => {
  const emailAddress = useRef(null);
  const password = useRef(null);
  const { i18n, t: translate } = useTranslation('common');

  const save = () => {};

  return (
    <div className={cls.modal}>
      <div className={cls.modal_header}>
        <h4>{ translate('settings.linking.email') }</h4>
        <Tooltip title="Close" arrow>
          <i className="fa-solid fa-xmark" onClick={close}></i>
        </Tooltip>
      </div>
      <div className={`${cls.modal_form} ${cls[i18n.language]}`}>
        <Input type="email" placeholder={ translate('settings.account.email') } className={cls.modal_form_input} ref={emailAddress} />
        <Input type="password" placeholder={ translate('settings.linking.password') } className={cls.modal_form_input} ref={password} />
      </div>
      <div className={cls.modal_action}>
        <button onClick={save}>{ translate('settings.linking.btn') }</button>
      </div>
    </div>
  )
}

export default OrderDetails