import { useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '../../store/slices/user';

import { useTranslation } from 'react-i18next';

import Cookies from 'universal-cookie';

import Input from '../UIs/IconInput';
import Tooltip from '@mui/material/Tooltip';

import Swal from 'sweetalert2';

import cls from './navbar.module.scss';

const Navbar = () => {
  const { i18n, t: translate } = useTranslation('common');
  const lang = i18n.language;
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const searchInput = useRef(null);
  const user = useSelector(state => state.user);

  const logout = async () => {
    await Swal.fire({
      title: translate('logoutConfirm'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: translate('yes'),
      cancelButtonText: translate('no'),
    }).then(async (result) => {
      if (result.value) {
        cookies.remove('markety-login-session');
        dispatch(UserActions.setUser(null));
      }
    })
  }

  const handleLangChange = () => {
    let lang = i18n.language === 'ar' ? 'en':'ar';
    cookies.set('marketyLang', lang);
    i18n.changeLanguage(lang);
    document.querySelector(":root").style.setProperty("--font-family", lang === 'ar' ? "'Baloo Bhaijaan 2'" : 'Rubik');
  }

  const handleSearch = () => {
    searchInput.showLoading();
    setTimeout(() => {
      console.log(searchInput.current.value);
      searchInput.endLoading();
    }, 3000);
  }
  
  return (
    <nav className={cls.navbar}>
      <div className={cls.navbar__logo} title="Markety Delivery">
        <img src="/assets/imgs/logo.png" alt="logo" className={cls[lang]} />
        <h4>{ translate('title') }<span> { translate('title.sub') } </span></h4>
      </div>
      <div className={cls.navbar__menu}>
        <div className={cls.nav__menu__breadcrumb}>
          <h5>
            {
              user.user && user.user.profile_picture ? 
                <img src={user.user.profile_picture} alt="user pricture" className={cls[lang]} />
                :
                <img src="https://cdn-icons-png.flaticon.com/512/149/149452.png" alt="user pricture" className={cls[lang]} />
            }
            { user.user && user.user.username }
          </h5>
        </div>
        <div className={`${cls.nav__menu__actions} ${cls[lang]}`}>
          <div className={cls.actions__search}>
            <Input
              type="search"
              placeholder={translate('search')}
              icon={<i className="fi fi-rr-search"></i>}
              onAction={handleSearch}
              ref={searchInput}
            />
          </div>
          <div className={`${cls.actions__btns} ${cls[lang]}`}>
            <div className={cls.actions__btn}>
              <Tooltip title={i18n.language === 'ar' ? 'تغيير إلى الانجليزية':'Switch to arabic'} placement="bottom">
                <i className="fi fi-rr-globe" onClick={handleLangChange}></i>
              </Tooltip>
            </div>
            <div className={cls.actions__btn}>
              <Tooltip title={translate('notifications')} placement="bottom">
                <i className="fi fi-rr-bell"></i>
              </Tooltip>
            </div>
            <div className={cls.actions__btn}>
              <Tooltip title={translate('logout')} placement="bottom">
                <i className="fi fi-rr-sign-out-alt" onClick={logout}></i>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;