import React from 'react'

import { useNavigate } from 'react-router-dom';

import cls from './notfound.module.scss';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className={cls.content}>
      <div>
        <h1>🕵🏻‍♀️</h1>
        <h2>404</h2>
        <p>Oops! The requested page was not found.</p>
        <button onClick={() => navigate('/')}>Return Home</button>
        <img src="/assets/imgs/notfound.svg" alt="not found" />
      </div>
    </div>
  )
}

export default NotFound