import { useState } from "react";
import Chart from "react-apexcharts";

const App = ({ data }) => {
  const [chartData] = useState({
    options: {
      legend: false,
      dataLabels: {
        enabled: false
      },
      labels: data.labels,
      colors:['#FF9900', '#0C7CE2', '#FF4765']
    },
    series: data.series
  });

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="donut"
            width="250"
          />
        </div>
      </div>
    </div>
  );
}

export default App;