import React from 'react';

import Breadcrumb from '../../components/Breadcrumb';
import AllReports from '../../components/Reports';

const Reports = () => {
  return (
    <div>
      <Breadcrumb page="reports" />
      <AllReports />
    </div>
  )
}

export default Reports