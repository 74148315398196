import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enAuth from './locales/en/auth.json';
import arAuth from './locales/ar/auth.json';

import arCommon from './locales/ar/common.json';
import enCommon from './locales/en/common.json';

import Cookies from 'universal-cookie';

const cookie = new Cookies()

const currentLang = cookie.get('marketyLang')

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: currentLang,
  resources: {
    en: {
      auth: enAuth,
      common: enCommon
    },
    ar: {
      auth: arAuth,
      common: arCommon
    }
  },
  ns: ['auth', 'common'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'ar'];

export default i18n;