import React from 'react';

import { useTranslation } from 'react-i18next';

import Box from "./Box/Box";

import cls from './boxes.module.scss';

const TopBoxes = () => {
  const { t: translate } = useTranslation('common');

  const boxes = [
    {
      title: translate('dashboard.orders'),
      icon: 'fa-duotone fa-box-taped',
      clr: '#FF9900',
      value: 127
    },
    {
      title: translate('dashboard.delivered'),
      icon: 'fa-duotone fa-box-circle-check',
      clr: '#0C7CE2',
      value: 102
    },
    {
      title: translate('dashboard.cancelled'),
      icon: 'fa-duotone fa-calendar-xmark',
      clr: '#FF4765',
      value: 25
    },
    {
      title: translate('dashboard.amounts'),
      icon: 'fa-duotone fa-money-bills',
      clr: '#00AD1C',
      value: 25765,
      prefix: "$"
    }
  ]

  return (
    <div className={cls.top_boxes}>
      {
        boxes.map((box, index) => (
          <Box key={index} {...box} />
        ))
      }
    </div>
  )
}

export default TopBoxes