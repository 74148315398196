import { useState, useEffect, useCallback, forwardRef } from 'react';

const Button = forwardRef((props, ref) => {
  const [content, setContent] = useState('');

  const showLoading = useCallback(() => {
    setContent(ref.current.innerHTML);
    ref.current.classList.add("btn-loading");
    ref.current.innerHTML = '<i class="fas fa-spinner-third fa-spin"></i>';
  }, [ref]);

  const endLoading = useCallback(() => {
    ref.current.classList.remove("btn-loading");
    ref.current.innerHTML = content;
  }, [ref, content]);

  useEffect(() => {
    if (ref) {
      ref.showLoading = showLoading;
      ref.endLoading = endLoading;
    }
  }, [ref, endLoading, showLoading]);

  return (
    <button {...props} ref={ref}>{ props.children }</button>
  )
});

export default Button;