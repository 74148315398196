import React from 'react';

import { useTranslation } from 'react-i18next';

import Formatter from 'react-currency-format';

import cls from './details.module.scss';

const Orders = () => {
  const { i18n, t: translate } = useTranslation('common');

  return (
    <div className={cls.details_body_table}>
      <div className={cls.details_body_table_header}>
        <div className={cls.details_body_table_header_item}>
          <p>{ translate('reports.details.id') }</p>
        </div>
        <div className={cls.details_body_table_header_item}>
          <p>{ translate('reports.details.customer') }</p>
        </div>
        <div className={cls.details_body_table_header_item}>
          <p>{ translate('reports.details.date') }</p>
        </div>
        <div className={cls.details_body_table_header_item}>
          <p>{ translate('reports.details.items') }</p>
        </div>
        <div className={cls.details_body_table_header_item}>
          <p>{ translate('reports.details.amount') }</p>
        </div>
      </div>
      
      <div className={cls.details_body_table_body}>
        <div className={cls.details_body_table_body_item}>
          <p>84321435457</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>John Doe <span className={cls.number}>[ +201146321817 ]</span></p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>22-06-2022 12:27 PM</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>15</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p><Formatter value={1200} prefix="$" thousandSeparator={true} displayType="text" /></p>
        </div>
      </div>
      <div className={cls.details_body_table_body}>
        <div className={cls.details_body_table_body_item}>
          <p>84321435457</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>John Doe <span className={cls.number}>[ +201146321817 ]</span></p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>22-06-2022 12:27 PM</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>15</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p><Formatter value={1200} prefix="$" thousandSeparator={true} displayType="text" /></p>
        </div>
      </div>
      <div className={cls.details_body_table_body}>
        <div className={cls.details_body_table_body_item}>
          <p>84321435457</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>John Doe <span className={cls.number}>[ +201146321817 ]</span></p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>22-06-2022 12:27 PM</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>15</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p><Formatter value={1200} prefix="$" thousandSeparator={true} displayType="text" /></p>
        </div>
      </div>
      <div className={cls.details_body_table_body}>
        <div className={cls.details_body_table_body_item}>
          <p>84321435457</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>John Doe <span className={cls.number}>[ +201146321817 ]</span></p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>22-06-2022 12:27 PM</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>15</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p><Formatter value={1200} prefix="$" thousandSeparator={true} displayType="text" /></p>
        </div>
      </div>
      <div className={cls.details_body_table_body}>
        <div className={cls.details_body_table_body_item}>
          <p>84321435457</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>John Doe <span className={cls.number}>[ +201146321817 ]</span></p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>22-06-2022 12:27 PM</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>15</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p><Formatter value={1200} prefix="$" thousandSeparator={true} displayType="text" /></p>
        </div>
      </div>

      <div className={cls.details_body_table_body}>
        <div className={cls.details_body_table_body_item}>
          <p></p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p></p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>{ translate('reports.details.total') }</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p>256</p>
        </div>
        <div className={cls.details_body_table_body_item}>
          <p><Formatter value={46520} prefix="$" thousandSeparator={true} displayType="text" /></p>
        </div>
      </div>
    </div>
  )
}

export default Orders