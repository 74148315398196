import React from 'react';

import { useTranslation } from 'react-i18next';

import Formatter from 'react-currency-format';

import cls from './details.module.scss';

const Order = () => {
  const { i18n, t: translate } = useTranslation('common');

  return (
    <>
      <div className={cls.details_body_summary}>
        <div className={cls.details_body_summary_item}>
          <p>{ translate('reports.details.orderedAt') }</p>
          <p>21-06-2022 01:09 AM</p>
        </div>
        <div className={cls.details_body_summary_item}>
          <p>{ translate('reports.details.orderedTo') }</p>
          <p>23street Sohag, Egypt</p>
        </div>
        <div className={cls.details_body_summary_item}>
          <p>{ translate('reports.details.payment') }</p>
          <p>{ translate('reports.details.payment.paypal') }</p>
        </div>
        <div className={cls.details_body_summary_item}>
          <p>{ translate('reports.details.customer') }</p>
          <p>John Doe [+201146321817]</p>
        </div>
      </div>
      <div className={`${cls.details_body_table} ${cls.order_details}`}>
        <div className={cls.details_body_table_header}>
          <div className={cls.details_body_table_header_item}>
            <p>{ translate('reports.details.id') }</p>
          </div>
          <div className={cls.details_body_table_header_item}>
            <p>{ translate('reports.details.name') }</p>
          </div>
          <div className={cls.details_body_table_header_item}>
            <p>{ translate('reports.details.qty') }</p>
          </div>
          <div className={cls.details_body_table_header_item}>
            <p>{ translate('reports.details.price') }</p>
          </div>
          <div className={cls.details_body_table_header_item}>
            <p>{ translate('reports.details.total') }</p>
          </div>
        </div>
        
        <div className={cls.details_body_table_body}>
          <div className={cls.details_body_table_body_item}>
            <p>1</p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p>Lenovo IdeaPad Gaming 3 Laptop - Ryzen 5 5600H 6-Core, 16GB RAM, 512GB SSD...</p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p>5</p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p><Formatter value={1200} prefix="$" thousandSeparator={true} displayType="text" /></p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p><Formatter value={6000} prefix="$" thousandSeparator={true} displayType="text" /></p>
          </div>
        </div>
        <div className={cls.details_body_table_body}>
          <div className={cls.details_body_table_body_item}>
            <p>1</p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p>Lenovo IdeaPad Gaming 3 Laptop - Ryzen 5 5600H 6-Core, 16GB RAM, 512GB SSD...</p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p>5</p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p><Formatter value={1200} prefix="$" thousandSeparator={true} displayType="text" /></p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p><Formatter value={6000} prefix="$" thousandSeparator={true} displayType="text" /></p>
          </div>
        </div>
        <div className={cls.details_body_table_body}>
          <div className={cls.details_body_table_body_item}>
            <p>1</p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p>Lenovo IdeaPad Gaming 3 Laptop - Ryzen 5 5600H 6-Core, 16GB RAM, 512GB SSD...</p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p>5</p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p><Formatter value={1200} prefix="$" thousandSeparator={true} displayType="text" /></p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p><Formatter value={6000} prefix="$" thousandSeparator={true} displayType="text" /></p>
          </div>
        </div>
        <div className={cls.details_body_table_body}>
          <div className={cls.details_body_table_body_item}>
            <p>1</p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p>Lenovo IdeaPad Gaming 3 Laptop - Ryzen 5 5600H 6-Core, 16GB RAM, 512GB SSD...</p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p>5</p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p><Formatter value={1200} prefix="$" thousandSeparator={true} displayType="text" /></p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p><Formatter value={6000} prefix="$" thousandSeparator={true} displayType="text" /></p>
          </div>
        </div>

        <div className={cls.details_body_table_body}>
          <div className={cls.details_body_table_body_item}>
            <p></p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p></p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p></p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p>{ translate('reports.details.total') }</p>
          </div>
          <div className={cls.details_body_table_body_item}>
            <p><Formatter value={24000} prefix="$" thousandSeparator={true} displayType="text" /></p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Order