import { useEffect } from 'react';

import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Formatter from 'react-currency-format';

import Orders from './Orders';
import Customers from './Customers';
import Amounts from './Amounts';
import Order from './Order';

import cls from './details.module.scss';

const Details = () => {
  const { i18n, t: translate } = useTranslation('common');
  const navigate = useNavigate();
  const { type } = useParams();
  const [searchParams] = useSearchParams();

  let title;

  switch (type) {
    case 'delivered-orders':
      title = translate('reports.titles.delivered');
      break;
    case 'cancelled-orders':
      title = translate('reports.titles.cancelled');
      break;
    case 'order-details':
      title = translate('reports.titles.details');
      break;
    case 'customers':
      title = translate('reports.titles.customers');
      break;
    case 'amounts':
      title = translate('reports.titles.amounts');
      break;
    default:
      break;
  }

  useEffect(() => {
    if (type === 'order-details' && !searchParams.get('order')) {
      navigate('/reports');
    }
    if (type !== 'order-details' && !searchParams.get('from')) {
      navigate('/reports');
    }
  }, [navigate, type, searchParams]);

  return (
    <div className={cls.details}>
      <div className={cls.details_tabe}>
        <p>{ title }</p>
        <p>22-06-2022 12:27 PM</p>
        <p>#456</p>
      </div>
      <div className={cls.details_header}>
        <div className={cls.details_header_logo}>
          <img src="/assets/imgs/logo.png" alt="logo" />
        </div>
        <div className={cls.details_header_details}>
          <h4>{ translate('reports.website') }</h4>
          <p>23 street Sohag, Egypt</p>
          <p>+20 114 632 1817</p>
        </div>
      </div>
      <div className={cls.details_metadata}>
        {
          type !== 'order-details' &&
          <p><span>{ translate('reports.details.dateRange') }: </span> { searchParams.get('from') || translate('reports.details.today') } { translate('reports.details.to') } { searchParams.get('to') || translate('reports.details.now') }</p>
        }
        { 
          type === 'customers' ? 
          <p><span>{ translate('reports.details.customers') }: </span> 125 { translate('reports.details.customer') }</p>
          :
          type === 'amounts' || type === 'order-details' ? 
          null
          :
          <p><span>{ translate('reports.details.orders') }: </span> 150 { translate('reports.details.order') }</p>
        }
        {
          type === 'customers' || type === 'order-details' ? 
          null
          :
          <p><span>{ translate('reports.details.totalAmount') }: </span> <Formatter value={125999} prefix="$" thousandSeparator={true} displayType="text" /></p>
        }
        {
          type === 'order-details' &&
          <>
            <p><span>{ translate('reports.details.order') }: </span> { searchParams.get('order') }</p>
            <p><span>{ translate('reports.details.status') }: </span> { translate('orders.delivered') }</p>
          </>
        }
      </div>
      <div className={cls.details_body}>
        {
          type === 'order-details' && <Order />
        }
        {
          type === 'customers' && <Customers />
        }
        {
          type === 'amounts' && <Amounts />
        }
        {
          (type === 'delivered-orders' || type === 'cancelled-orders') && <Orders />
        }
      </div>
    </div>
  )
}

export default Details