import { configureStore } from '@reduxjs/toolkit';

//= Slices
import User from './slices/user';

const store = configureStore({
  reducer: {
    user: User
  }
})

export default store;