import React from 'react';

import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

const Accordion = ({ className, open, children }) => {
  return (
    <SlideDown className={className}>
      {
        open ? children : null
      }
    </SlideDown>
  )
}

export default Accordion