import { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import Input from '../../UIs/Input';
import Button from '../../UIs/Button';

import cls from './security.module.scss';

const Security = () => {
  const { i18n, t: translate } = useTranslation('common');

  const current_password = useRef(null);
  const new_password = useRef(null);
  const confirm_password = useRef(null);
  const saveBtn = useRef(null);

  const savePassword = () => {
    saveBtn.showLoading();

    setTimeout(() => {
      saveBtn.endLoading();
    }, 2500);
  }

  return (
    <div className={cls.security}>
      <div className={`${cls.form} ${cls[i18n.language]}`}>
        <Input type="password" placeholder={ translate('settings.security.current_password') } className={cls.security__input} ref={current_password} />
        <Input type="password" placeholder={ translate('settings.security.new_password') } className={cls.security__input} ref={new_password} />
        <Input type="password" placeholder={ translate('settings.security.confirm_password') } className={cls.security__input} ref={confirm_password} />
        <Button ref={saveBtn} onClick={savePassword}>{ translate('save') }</Button>
      </div>
      <div className={cls.image}>
        <i className="fi fi-rr-unlock"></i>
      </div>
    </div>
  )
}

export default Security;