import React from 'react';

import { Navbar, Sidebar } from '../components';

const MainLayout = ({ children }) => {
  return (
    <div className="main-layout">
      <Navbar />
      <Sidebar />
      <div className="page">
        { children }
        <footer>
          <p><a href="https://hassanali.tk" target="_blank" rel="noreferrer"><u>Hassan Ali</u></a> - Markety Store &copy; {new Date().getFullYear()}</p>
        </footer>
      </div>
    </div>
  )
}

export default MainLayout;