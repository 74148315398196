import React from 'react';

import { useTranslation } from 'react-i18next';

import cls from './breadcrumb.module.scss';

const Breadcrumb = ({ page }) => {
  const { t: translate } = useTranslation('common');

  return (
    <div className={cls.breadcrumb}>
      <h4><i className="fa-duotone fa-grip-lines"></i> { translate(`nav.${page}`) }</h4>
    </div>
  )
}

export default Breadcrumb