import React from 'react';

import { useTranslation } from 'react-i18next';

import Formatter from 'react-currency-format';
import PieChart from './PieChart';

import cls from "./customers.module.scss";

const Customers = () => {
  const { i18n, t: translate } = useTranslation('common');

  const chartData = {
    labels: [translate('dashboard.customers.total'), translate('dashboard.customers.new')],
    series: [4560, 1250]
  }

  return (
    <div className={cls.customers}>
      <div className={cls.customers__title}>
        <h4>{ translate('dashboard.customers.title') }</h4>
      </div>
      <div className={cls.customers__charts_bar}>
        <PieChart data={chartData} />
      </div>
      <div className={cls.customers__numbers}>
        <div className={cls.customers__numbers_number}>
          <h4><Formatter value={4560} thousandSeparator={true} displayType="text" /></h4>
          <h6 className={cls[i18n.language]}>{ translate('dashboard.customers.total') }</h6>
        </div>
        <div className={cls.customers__numbers_number}>
          <h4><Formatter value={1250} thousandSeparator={true} displayType="text" /></h4>
          <h6 className={cls[i18n.language]}>{ translate('dashboard.customers.new') }</h6>
        </div>
      </div>
    </div>
  )
}

export default Customers;