import { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const App = ({ data }) => {
  const [chartData] = useState({
    options: {
      legend: false,
      labels: data.labels,
      colors:['#0C7CE2', '#00AD1C']
    },
    series: data.series
  });

  const [width, setWidth] = useState('90%');

  const checkWidth = () => {
    if (window.innerWidth > 1600) setWidth('90%');
    else if (window.innerWidth < 1600 && window.innerWidth > 992) setWidth('100%');
    else if (window.innerWidth < 992 && window.innerWidth > 450) setWidth('70%'); 
    else if (window.innerWidth < 450) setWidth('100%'); 
  } 

  useEffect(() => {
    checkWidth();
    window.onresize = () => checkWidth();
  }, []);

  return (
    <div className="pie_chart" style={{ width, margin: 'auto' }}>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="pie"
      />
    </div>
  );
}

export default App;