import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
//= Layouts
import MainLayout from "../layouts/Main";
import AuthLayout from "../layouts/Auth";
//= Pages
import { Dashboard, Orders, Reports, Logs, Settings, Login, Forget, Reset, NotFound } from '../pages';
import ReportDetails from '../components/Reports/Details';

const GuardedRoute = ({ Component, RequireAuth, RequireNoAuth }) => {
  const user = useSelector(state => state.user.user);

  if (RequireAuth) {
    return !user ? 
      <Navigate to='/login' /> 
      :
      (
        <MainLayout>
          <Component />
        </MainLayout>
      )
  } else if (RequireNoAuth) {
    return user ?
      <Navigate to='/' />
      :
      (
        <AuthLayout>
          <Component />
        </AuthLayout>
      )
  } else {
    return <Component />
  }
}

const AppRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<GuardedRoute Component={Dashboard} RequireAuth />} />
      <Route path="/orders" element={<GuardedRoute Component={Orders} RequireAuth />} />
      <Route path="/reports" element={<GuardedRoute Component={Reports} RequireAuth />} />
      <Route path="/reports/:type" element={<GuardedRoute Component={ReportDetails} RequireAuth />} />
      <Route path="/logs" element={<GuardedRoute Component={Logs} RequireAuth />} />
      <Route path="/settings" element={<GuardedRoute Component={Settings} RequireAuth />} />
      <Route path="/login" element={<GuardedRoute Component={Login} RequireNoAuth />} />
      <Route path="/forget" element={<GuardedRoute Component={Forget} RequireNoAuth />} />
      <Route path="/reset" element={<GuardedRoute Component={Reset} RequireNoAuth />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AppRoutes;