import React from 'react';

import { useTranslation } from 'react-i18next';

import Formatter from 'react-currency-format';
import DonutChart from './DonutChart';

import cls from "./summary.module.scss";

const Summary = () => {
  const { i18n, t: translate } = useTranslation('common');

  const chartData = {
    labels: [translate('dashboard.summary.onDelivery'), translate('dashboard.summary.delivered'), translate('dashboard.summary.cancelled')],
    series: [75, 124, 12]
  }
  
  return (
    <div className={cls.summary}>
      <div className={cls.summary__title}>
        <h4>{ translate('dashboard.summary.title') }</h4>
      </div>
      <div className={cls.summary__filter}>
        <select>
          <option>{ translate('dashboard.filter.today') }</option>
          <option>{ translate('dashboard.filter.lweek') }</option>
          <option>{ translate('dashboard.filter.l30days') }</option>
          <option>{ translate('dashboard.filter.lmonth') }</option>
          <option>{ translate('dashboard.filter.lyear') }</option>
        </select>
      </div>
      <div className={cls.summary__numbers}>
        <div className={cls.summary__numbers_number}>
          <h3><Formatter value={75} thousandSeparator={true} displayType="text" /></h3>
          <h6 className={cls[i18n.language]}>{ translate('dashboard.summary.onDelivery') }</h6>
        </div>
        <div className={cls.summary__numbers_number}>
          <h3><Formatter value={124} thousandSeparator={true} displayType="text" /></h3>
          <h6 className={cls[i18n.language]}>{ translate('dashboard.summary.delivered') }</h6>
        </div>
        <div className={cls.summary__numbers_number}>
          <h3><Formatter value={12} thousandSeparator={true} displayType="text" /></h3>
          <h6 className={cls[i18n.language]}>{ translate('dashboard.summary.cancelled') }</h6>
        </div>
      </div>
      <div className={cls.summary__charts}>
        <div className={cls.summary__charts_bar}>
          <DonutChart data={chartData} />
        </div>
        <div className={cls.summary__charts_progress}>
          <div className={cls.summary__charts_progress_item}>
            <div className={cls.item_bar}>
              <span style={{ backgroundColor: "#FF9900", width: '35.5%' }}></span>
            </div>
            <div className={cls.item_text}>
              <p><span className={cls[i18n.language]}>35.5% </span> { translate('dashboard.summary.onDelivery') }</p>
            </div>
          </div>
          <div className={cls.summary__charts_progress_item}>
            <div className={cls.item_bar}>
              <span style={{ backgroundColor: "#0C7CE2", width: '58.75%' }}></span>
            </div>
            <div className={cls.item_text}>
              <p><span className={cls[i18n.language]}>58.75% </span> { translate('dashboard.summary.delivered') }</p>
            </div>
          </div>
          <div className={cls.summary__charts_progress_item}>
            <div className={cls.item_bar}>
              <span style={{ backgroundColor: "#FF4765", width: '5.75%' }}></span>
            </div>
            <div className={cls.item_text}>
              <p><span className={cls[i18n.language]}>5.75% </span> { translate('dashboard.summary.cancelled') }</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Summary;