import React from 'react';

import Breadcrumb from '../../components/Breadcrumb';
import TopBoxes from '../../components/Dashboard/TopBoxes';
import Summary from '../../components/Dashboard/Summary';
import Customers from '../../components/Dashboard/Customers';
import Earnings from '../../components/Dashboard/Earnings';

import { Grid } from '@mui/material';

const Dashboard = () => {
  return (
    <div>
      {/* Page Breadcrumb */}
      <Breadcrumb page="dashboard" />
      {/* Analytics Boxes */}
      <TopBoxes />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8} xl={9}>
          {/* Orders Summary */}
          <Summary />
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          {/* Customers Infograph */}
          <Customers />
        </Grid>
      </Grid>
      {/* Earnings Chart */}
      <Earnings />
    </div>
  )
}

export default Dashboard;