import React from 'react';

import Breadcrumb from '../../components/Breadcrumb';
import TabNav from '../../components/Orders/TabNav';
import OrderBoxes from '../../components/Orders/OrderBoxes';

const Orders = () => {
  return (
    <div>
      <Breadcrumb page="orders" />
      <TabNav />
      <OrderBoxes />
    </div>
  )
}

export default Orders;