import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { UserActions } from '../../store/slices/user';

import { useTranslation } from 'react-i18next';

import Button from "../../components/UIs/Button";
import Input from '../../components/UIs/Input';

import { success, error } from '../../utils/notify';

import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import Cookies from 'universal-cookie';

import cls from './login.module.scss';

const Login = () => {
  const { i18n, t: translate } = useTranslation('auth');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const googleLoginBtn = useRef(null);
  const facebookLoginBtn = useRef(null);
  const loginBtn = useRef(null);

  const email = useRef(null);
  const password = useRef(null);
  
  const cookies = new Cookies();

  const handleLogin = async () => {
    let credentials = {
      email: email.current.value,
      password: password.current.value
    };

    //= Validation
    if (!credentials.email) return error(translate('auth.error.requiredEmail'));
    if (!credentials.password) return error(translate('auth.error.requiredPassword'));
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(credentials.email)) return error(translate('auth.error.invalidEmail'));

    loginBtn.showLoading();

    if (credentials.email !== 'admin@markety.ml' || credentials.password !== 'admin') {
      setTimeout(() => loginBtn.endLoading(), 500);
      return error(translate('auth.error.invalidCredentials'));
    }

    const user = {
      email: credentials.email,
      username: "Hassan Ali"
    }

    cookies.set('markety-delivery-login', JSON.stringify(user), { path: '/' });
    dispatch(UserActions.setUser(user));
    success(`${translate('welcome')}, ${user.username}`);
    loginBtn.endLoading();
  }

  const handleGoogleLogin = async (response) => {
    googleLoginBtn.showLoading();

    const user = {
      email: "admin@markety.ml",
      username: "Hassan Ali"
    }

    dispatch(UserActions.setUser(user));
    googleLoginBtn.endLoading();
  }

  const handleFacebookLogin = async (response) => {
    if (response.status === "unknown") return;

    const user = {
      email: "admin@markety.ml",
      username: "Hassan Ali"
    }

    dispatch(UserActions.setUser(user));
    facebookLoginBtn.endLoading();
  }

  const goToForgetPage = e => {
    e.preventDefault();
    navigate('/forget');
  }

  return (
    <div className={cls.login}>
      <div className={cls.login__container}>
        <div className={cls.login__container__logo}>
          <img src="/assets/imgs/logo.png" alt="logo" />
          <h4>{ translate('title') }</h4>
        </div>

        <div className={cls.login__container__title}>
          <h3>{ translate('welcome') }</h3>
        </div>
        <div className={cls.login__withProvider}>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            render={renderProps => (
              <Button 
                className={`${cls.login__withProvider__button} ${cls.google} ${cls[i18n.language]}`}
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                ref={googleLoginBtn}
              >
                <img src="/assets/imgs/auth/G_logo.svg" alt="google" />
                <p>{ translate('auth.googleSign') }</p>
              </Button>
            )}
            buttonText="Login"
            onSuccess={handleGoogleLogin}
            cookiePolicy={'single_host_origin'}
          />

          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            autoLoad={false}
            callback={handleFacebookLogin}
            render={renderProps => (
              <Button className={`${cls.login__withProvider__button} ${cls.facebook} ${cls[i18n.language]}`} onClick={renderProps.onClick} ref={facebookLoginBtn}>
                <img src="/assets/imgs/auth/F_logo.svg" alt="facebook" />
                <p>{ translate('auth.facebookSign') }</p>
              </Button>
            )}
          />
        </div>

        <div className={cls.login__container__or}>
          <p>{ translate('auth.or') }</p>
        </div>

        <div className={cls.login__container__form}>
          <Input id="email" type="email" placeholder={ translate('auth.email') + ' (admin@markety.ml)' } className={`${cls.login__container__form__input} ${cls[i18n.language]}`} ref={email} />
          <Input id="password" type="password" placeholder={ translate('auth.password') + ' (admin)' } className={`${cls.login__container__form__input} ${cls[i18n.language]}`} ref={password} />
          <a href="/forget" onClick={goToForgetPage}>{ translate('forget.forgetPassword?') }</a>
          <Button className={cls.login__container__form__button} onClick={handleLogin} ref={loginBtn}>{ translate('auth.login') }</Button>
        </div>
      </div>

      <div className={cls.login__container__footer}>
        <p>
          { translate('auth.footer') }
        </p>
      </div>
    </div>
  )
}

export default Login;