import { useState, useEffect } from 'react';

import { Helmet } from "react-helmet";

import AppRoutes from './routes';

import { Loader } from './components';

import { useDispatch } from 'react-redux';
import { UserActions } from './store/slices/user';

import { useTranslation } from 'react-i18next';

import { ToastContainer } from 'react-toastify';

import ThemeContextProvider from './theme/theme';

import Cookies from 'universal-cookie';

import axios from './utils/axios';

import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/ar';

import './styles/globals.scss'
import './styles/variables.scss'
import './assets/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  dayjs.extend(localeData);

  const cookies = new Cookies();
  const userCookie = cookies.get('markety-delivery-login');

  const getCurrentUser = async () => {
    dispatch(UserActions.setUser(userCookie));
    setLoading(false);
  }

  useEffect(() => {
    if (userCookie) getCurrentUser();
    else setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCookie])

  useEffect(() => {
    if (i18n.language === 'ar') dayjs.locale('ar');
  }, [i18n]);

  useEffect(() => {
    setLang(i18n.language);
    document.querySelector(":root").style.setProperty("--font-family", i18n.language === 'ar' ? "'Baloo Bhaijaan 2'" : 'Rubik');
    //= Setup default language for api requests
    axios.defaults.headers.common['Accept-Language'] = i18n.language;
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        <title>
          {lang === 'en' ? 'Markety - Devliery App' : 'ماركتي - تطبيق الديلفيري'}
        </title>
      </Helmet>
      <main id={lang}>
        <ThemeContextProvider>
          {
            loading ?
              <Loader />
              :
              <AppRoutes />
          }
          {/* Toast Container */}
          <ToastContainer
            position={lang === 'en' ? "top-right" : "top-left"}
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          />
        </ThemeContextProvider>
      </main>
    </>
  );
}

export default App;
