import React from 'react'

import { useTranslation } from 'react-i18next';

import cls from './notifications.module.scss';

const Notifications = () => {
  const { t: translate } = useTranslation('common');

  return (
    <div className={cls.notifications}>
      <h3>{ translate('settings.notifications.announce') }</h3>
    </div>
  )
}

export default Notifications